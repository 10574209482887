import React from 'react';
import '../../assets/styles/base/popup.scss';
import CloseIcon from '../../images/icon-close.inline.svg';

const InsurancePopup = ({ insurancePopup, setInsurancePopup }) => {
  return (
    <>
      <div
        className={`popup-overlay ${insurancePopup ? 'active' : ''}`}
        onClick={(e) => {
          e.preventDefault();
          setInsurancePopup(!insurancePopup);
        }}
      />
      <div className={`popup-wrapper ${insurancePopup ? 'active' : ''}`}>
        <CloseIcon className="close-icon"
          onClick={(e) => {
            e.preventDefault();
            setInsurancePopup(!insurancePopup);
          }} />
        <h2 className="popup-title">DingGo + Car Insurance</h2>
        <div className="popup-body">
          <p>
            Want to use insurance? No worries you can use your insurance with DingGo. You get to choose the repairer you want local to you whilst also supporting your local smash repair business. All you have to do is select your preferred quote and then get in touch with us to let us know you want to use insurance. We will get a few more details and take care of the rest for you. It's that easy.
          </p>
          <p>
            Unsure whether you should use insurance or pay for it privately? No worries please see our FAQ here or give us a call and we can help you make the best decision.
          </p>
          <p>
            <strong>You can reach us via:</strong><br />
            Phone: 0000000000<br />
            Email: test@dinggo.com.au<br />
            <u>Online chat below</u>
          </p>
          <p>
            <em>Please note that some insurers don't allow you the freedom to choose your own repairer. To find out if your insurer does just as them if you have 'choice of repairer' on your policy. If you don't then unfortunately we won't be able to help you as you have to use their chosen repair shops.</em>
          </p>
        </div>
      </div>
    </>
  );
};

export default InsurancePopup;

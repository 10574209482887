import React from 'react';
import '../../assets/styles/base/popup.scss';
import CloseIcon from '../../images/icon-close.inline.svg';

const InsurancePopup = ({ contactlessPopup, setContactlessPopup }) => {
  return (
    <>
      <div
        className={`popup-overlay ${contactlessPopup ? 'active' : ''}`}
        onClick={(e) => {
          e.preventDefault();
          setContactlessPopup(!contactlessPopup);
        }}
      />
      <div className={`popup-wrapper ${contactlessPopup ? 'active' : ''}`}>
        <CloseIcon className="close-icon"
          onClick={(e) => {
            e.preventDefault();
            setContactlessPopup(!contactlessPopup);
          }} />
        <h3 className="popup-title">What is a contactless repair?</h3>
        <div className="popup-body">
          <p>
            A contactless repair is a new option where you can now have your repair done with no direct contact with your repairer.
          </p>
          <p>
            <strong>Workshop Repairs</strong><br />
            For workshops this involves either a:<br />
            1. A safe key drop off at the workshop when you drop off/pickup your car.<br />
            2. If the repairer agrees they will pick up and drop off your car for you and you just have to organise to leave the car and keys somewhere safe for them to collect.
          </p>
          <p>
            <strong>Mobile Repairs</strong><br />
            For mobile repairs you just have to organise to leave the car and keys out for them in a safe place where they have access to power and a secure area to access and repair your car.
          </p>
          <p>
            <strong>Hygiene Practices</strong><br />
            All our panel beaters are implementing government recommendations regarding social distancing, hand washing and regular cleaning of the workshops. In addition they have taken further steps to protect themselves, their employees and customers by disinfecting every car before it comes into their shop and before the car is returned to you.
          </p>
        </div>
      </div>
    </>
  );
};

export default InsurancePopup;

import React, { useState } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { calculateRating } from '../components/helper';
import '../assets/styles/pages/quote/selection.scss';

import Layout from '../components/layout';
import SEO from '../components/seo';
import SelectionDate from '../components/quotes/selection-date';
import InsurancePopup from '../components/quotes/insurance-popup';
import PayDifferencePopup from '../components/quotes/pay-difference-popup';
import ContactlessPopup from '../components/quotes/contactless-popup';

const QuoteIndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      quoteImage: file(relativePath: { eq: "quote-beater-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      zipSmall: file(relativePath: { eq: "zip-pay-small.png" }) {
        childImageSharp {
          fluid(maxWidth: 60) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      zip {
        environment
        publicKey
      }
    }
  `);
  const [showSelectDateModel, setSelectDateModel] = useState(false);
  const [insurancePopup, setInsurancePopup] = useState(false);
  const [payDifferencePopup, setPayDifferencePopup] = useState(false);
  const [contactlessPopup, setContactlessPopup] = useState(false);
  const { environment, publicKey } = data.zip;

  return (
    <Layout wrapperClass="quote-selection-container" hideHeaderFooter={true}>
      <SEO title="Quote Selection" />
      <div className="quote-selection-wrapper">
        <div className="title-wrapper">
          <div className="container">
            <h1>Here are your quotes</h1>
            <p className="intro">
              Review your offers and schedule a date to repair your car.
            </p>
            <div className="reference-wrapper">
              REF # 94C11L - 2017 Blue Holden Commodore
            </div>
          </div>
        </div>
        <div className="container">
          <div className="guote-row-wrapper">
            {[...Array(3)].map((x, i) => (
              <div className="quote-row" key={i}>
                <Link to="/quote-detail" className="quote-row-image-wrapper">
                  <Img fluid={data.quoteImage.childImageSharp.fluid} />
                </Link>
                <div className="quote-row-location">
                  <h2><Link to="/quote-detail">Marrickville Prestige Smash Repairs</Link></h2>
                  <div className="rating-wrapper">
                    <span className="location">Camperdown - 1.45 km away</span>
                    <span className="rating-number">{calculateRating(i)}</span>
                    <div className="rating-stars-container">
                      <span className="rating-stars rating-stars-off">★★★★★</span>
                      <span className="rating-stars rating-stars-on" style={{ width: `${calculateRating(i)}%` }}>★★★★★</span>
                    </div>
                    <span className="rating-count">(12)</span>
                  </div>
                  <div className="quote-label">Contactless repairer</div>
                  <div className="quote-info">
                    <p>
                      <strong>Services</strong> Lifetime warranty on all work &bull; Lifetime warranty on all work
                    </p>
                    <p>
                      <strong>Overview of work</strong>Repair damaged area and repaint to match surrounding panels. No parts required.
                    </p>
                  </div>
                </div>
                <div className="quote-row-pricing">
                  <span className="quote-price">$865</span>
                  <div className="quote-availibility">
                    Available from: The 22nd Oct
                    <br />
                    Repair Time: 3 Days
                  </div>
                  <span onClick={(e) => {
                    e.preventDefault();
                    setSelectDateModel(!showSelectDateModel);
                  }} className="btn">Select a date</span>
                </div>
              </div>
            ))}
          </div>
          <div className="payment-information-wrapper">
            <h2>About Payment</h2>
            <div className="payment-information-container">
              {environment !== '' && publicKey !== '' && (
                <div className="zip-wrapper">
                  <h3>No Credit Card Required</h3>
                  <ul>
                    <li>Pay only when your repair is completed</li>
                    <li>Now accepting payments with
                      <span className="zip-image">
                        <Img fluid={data.zipSmall.childImageSharp.fluid} />
                      </span>
                    </li>
                  </ul>
                </div>
              )}
              <div className="insurance-wrapper">
                <p>Want to use insurance? <span onClick={(e) => {
                  e.preventDefault();
                  setInsurancePopup(!insurancePopup);
                }}>DingGo Can Help</span></p>
                <p>
                  <span onClick={(e) => {
                    e.preventDefault();
                    setContactlessPopup(!contactlessPopup);
                  }}>What is a contactless repair?</span>
                </p>
                <span onClick={(e) => {
                  e.preventDefault();
                  setPayDifferencePopup(!payDifferencePopup);
                }}>Why the price difference?</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SelectionDate showSelectDateModel={showSelectDateModel} setSelectDateModel={setSelectDateModel} />
      <InsurancePopup insurancePopup={insurancePopup} setInsurancePopup={setInsurancePopup} />
      <PayDifferencePopup payDifferencePopup={payDifferencePopup} setPayDifferencePopup={setPayDifferencePopup} />
      <ContactlessPopup contactlessPopup={contactlessPopup} setContactlessPopup={setContactlessPopup} />
    </Layout>
  );
};

export default QuoteIndexPage;

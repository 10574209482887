import React from 'react';
import '../../assets/styles/base/popup.scss';
import CloseIcon from '../../images/icon-close.inline.svg';

const InsurancePopup = ({ payDifferencePopup, setPayDifferencePopup }) => {
  return (
    <>
      <div
        className={`popup-overlay ${payDifferencePopup ? 'active' : ''}`}
        onClick={(e) => {
          e.preventDefault();
          setPayDifferencePopup(!payDifferencePopup);
        }}
      />
      <div className={`popup-wrapper ${payDifferencePopup ? 'active' : ''}`}>
        <CloseIcon className="close-icon"
          onClick={(e) => {
            e.preventDefault();
            setPayDifferencePopup(!payDifferencePopup);
          }} />
        <h2 className="popup-title">Why the price difference?</h2>
        <div className="popup-body">
          <p>
            DingGo is a closed quoting system
            Repairers have no insight into the price of their competitors. This ensures that you pay a fair price.
          </p>
          <p>
            <strong>Repairer’s current capacity</strong><br />
            The availability and demand of a repairer will often influence their prices. When a workshop is quiet it’s common to be offered more favourable prices compared to when they are very busy.
            Replacing or repairing parts
            Depending on the level of damage, some repairers might recommend a replacement part while others will recommend simply repairing it. Like all professionals it’s not uncommon to have differing opinions.
          </p>
          <p>
            <strong>Types of Parts and access to them</strong><br />
            Different repairers source their parts at different costs depending on their networks. For cars within their manufacturer’s warranty period, all repairers will only ever use new and genuine manufacturer parts to maintain the vehicle’s warranty. This often extends to older prestige vehicles as well. For vehicles outside their warranty period, it is common to use refurbished parts to save you money.
          </p>
          <p>
            <strong>Repairing equipment and techniques</strong><br />
            Repairers have different techniques and efficiencies. Some repairers invest heavily in equipment and technology meaning they can perform your required repair at a lower cost.
          </p>
          <p>
            <strong>Quality</strong><br />
            All repairers are licensed and offer varying warranties on their work. This means your vehicle will always be repaired professionally. Repairing to manufacturer specifications also means any new car warranty remains valid.
          </p>
        </div>
      </div>
    </>
  );
};

export default InsurancePopup;
